import { IUser } from '@kidsmanager/util-models';
import { IAuth, fetch } from '../auth';
import { BackendCache } from '../backend-cache';

const cache = new BackendCache();

export class ApiAdminUsers {
  constructor(
    private auth: IAuth,
    private fetch: fetch
  ) {}

  async all(): Promise<IUser[]> {
    return cache.get('admin_users', async () => {
      const result = await this.fetch('api/admin/users');
      if (result.ok) {
        return result.json();
      }
      return [];
    });
  }

  async google(): Promise<string[]> {
    const response = await this.fetch('/api/admin/google/users');
    if (response.ok) {
      return response.json();
    }
    return [];
  }
}
