import { IShiftSpec } from '@kidsmanager/util-models';

export interface SelectableShiftSpec extends IShiftSpec {
  readonly?: boolean;
  infoOnly?: boolean;
  selected?: boolean;
  hide?: boolean;
  disabled?: boolean;
  tip?: string;
}

export interface UserData {
  id: string;
  name: string;
  contractedHrs: number;
  holidays: number;
  workingDays: number;
  hoursInMonth: number;
  carryOver: number;
  adjustment: number;
  goal: number;
  available: number;
  warn?: 'low' | 'high';
}

export const emptyUserData: UserData = {
  id: '',
  name: '',
  contractedHrs: 0,
  holidays: 0,
  workingDays: 0,
  hoursInMonth: 0,
  carryOver: 0,
  adjustment: 0,
  goal: 0,
  available: 0
};

export interface DayData {
  day: number;
  date: Date;
  workday: boolean;
  cover?: 'minimal' | 'full';
}
