import { IAuth, fetch } from '../auth';
import { BackendCache } from '../backend-cache';
import { IShift, IWeek, IValueFrom, IMonth } from '@kidsmanager/util-models';
import { hoursForMonth, IHoursForMonth } from './api-timesheet-helper';
import { ApiTimesheetLog } from './api-timesheet-log';

const cache = new BackendCache();

export class ApiTimesheet {
  constructor(
    private auth: IAuth,
    private fetch: fetch
  ) {
    this.log = new ApiTimesheetLog(auth, fetch, (key) => cache.clear(key));
  }

  log: ApiTimesheetLog;

  async shifts(userId?: string): Promise<IShift[]> {
    userId = userId || 'my';
    return cache.get(`shifts-${userId}`, async () => {
      return (await this.fetch(`/api/work/${userId}/shifts`)).json();
    });
  }

  async week(date: Date, userId?: string): Promise<IWeek> {
    userId = userId || 'my';
    const year = date.getFullYear();
    const week = date.getWeek();
    return cache.get(`worked-${userId}-${week}`, async () => {
      return (
        await this.fetch(`/api/work/${userId}/week/${year}/${week}`)
      ).json();
    });
  }

  async month(year: number, month: number, userId?: string): Promise<IMonth> {
    userId = userId || 'my';
    return cache.get(`worked-${userId}-${year}-${month}`, async () => {
      return (
        await this.fetch(`/api/work/${userId}/month/${year}/${month}`)
      ).json();
    });
  }

  async contracted(month: Date, userId?: string): Promise<IHoursForMonth> {
    const budgets = await this.valuesFrom('contracted-hours', userId);
    return hoursForMonth(budgets, month);
  }

  async valuesFrom(type: string, userId?: string): Promise<IValueFrom[]> {
    userId = userId || 'my';
    return cache.get(`valueFrom-${type}-${userId}`, async () => {
      const values = (await (
        await this.fetch(`/api/work/${userId}/valueFrom/${type}`)
      ).json()) as IValueFrom[];
      values.sort((a, b) => (a.from > b.from ? 1 : -1));
      return values;
    });
  }
}
