import { FormEvent, useContext, useEffect, useState } from 'react';
import { ClientBackendContext } from '@kidsmanager/ui-api';
import {
  Button,
  Checkbox,
  IconGoogle,
  Input,
  LoadSpinner,
  Logo
} from '@kidsmanager/ui-core';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { linkWithLegacy } from '../helpers/linking';
import { SsoLoginMessage } from '../feature-google-callback/feature-google-callback';

export interface FeatureLoginProps {
  brand: 'kidsmgr' | 'zeitmgr';
  linkWithLegacy?: boolean;
}

export const FeatureLogin = (props: FeatureLoginProps) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const client = useContext(ClientBackendContext);
  const [params] = useSearchParams();
  const navigate = useNavigate();

  const returnUrl = params.get('returnUrl') || '/';

  useEffect(() => {
    if (localStorage.getItem('refreshToken') !== null) {
      navigate(returnUrl);
    }
  }, [navigate, returnUrl]);

  const onUsernameChange = (value: string) => {
    const fixedUsername = value.replace(/[/]/g, '\\');
    setUsername(fixedUsername);
  };

  const invalidLogindata = () => {
    const validUsernameRegex = /^[a-zA-Z]{2,}\\.{2,}$/;
    return !username || !password || !validUsernameRegex.test(username);
  };

  const handleLegacyLogin = async (event: FormEvent) => {
    event.preventDefault();
    setLoading(true);
    const state = await client.auth.authenticateUsernamePassword(
      username,
      password,
      rememberMe
    );
    if (state === 'Authenticated') {
      if (props.linkWithLegacy) {
        const origin = window.location.origin;
        const { tenant, otp } = await client.apiOauth2.link();
        linkWithLegacy(
          tenant,
          otp,
          origin,
          rememberMe,
          returnUrl,
          navigate,
          () => {
            setLoading(false);
          }
        );
      } else {
        navigate(returnUrl);
      }
    } else {
      setLoading(false);
      setErrorMessage('Login fehlgeschlagen');
    }
  };

  const handleGoogleLogin = (email: string | null) => {
    const completeGoogleLogin = async ({ data }: { data: SsoLoginMessage }) => {
      window.removeEventListener('message', completeGoogleLogin);
      if (data?.success) {
        console.log('Completing Google login...');
        const { token, refreshToken, tenant, singleUser } = data;
        client.auth.updateTokens(token, refreshToken);
        client.auth.updateTenant(tenant);
        localStorage.setItem('idp', 'google');
        if (props.linkWithLegacy) {
          console.log('Linking with legacy...');
          const origin = window.location.origin;
          const { tenant, otp } = await client.apiOauth2.link();
          linkWithLegacy(tenant, otp, origin, singleUser, returnUrl, () => {
            setLoading(false);
          });
        } else {
          console.log('no linking, navigating to return url', returnUrl);
          navigate(returnUrl);
        }
      } else {
        console.error('Google login failed', data);
      }
    };

    console.log(`Start Google login...`);
    const width = 600;
    const height = 700;
    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;
    const windowProps = `toolbar=no, menubar=no, width=${width}, height=${height}, top=${top}, left=${left}`;
    const tenant = localStorage.getItem('sso-tenant');
    const origin = window.location.origin;
    client.apiOauth2
      .googleWorkspaceUrl('USER', tenant, origin, email)
      .then((url) => {
        window.addEventListener('message', completeGoogleLogin);
        setTimeout(() => window.open(url.url, '', windowProps), 100);
      });
  };

  return (
    <div className="mx-4 pt-44 text-center">
      <div className="mx-auto max-w-md px-2">
        <h1 className="flex items-center text-4xl">
          {props.brand === 'kidsmgr' && (
            <>
              <Logo format="icon" brand="kidsmgr" />
              <span className="font-bold">Kids</span>
              <span className="font-extralight">Manager</span>
            </>
          )}
          {props.brand === 'zeitmgr' && (
            <>
              <Logo format="icon" brand="zeitmgr" />
              <span className="font-bold">Zeit</span>
              <span className="font-extralight">Manager</span>
            </>
          )}
        </h1>
        <form
          className="flex max-w-md flex-col gap-2 pt-2"
          onSubmit={handleLegacyLogin.bind(this)}
        >
          <Input
            id="username"
            placeholder="Firma\Benutzername"
            value={username}
            onChange={onUsernameChange.bind(this)}
          />
          <Input
            id="password"
            placeholder="Passwort"
            type="password"
            value={password}
            onChange={setPassword.bind(this)}
          />
          <div className="text-left text-sm">
            <Checkbox
              checked={rememberMe}
              onChange={(value) => setRememberMe(value)}
              label="Angemeldet bleiben?"
            ></Checkbox>
          </div>
          <div className="flex items-center justify-end">
            <LoadSpinner show={loading}>
              <Button disabled={invalidLogindata()}>Einloggen</Button>
            </LoadSpinner>
          </div>
        </form>
        <div className="h-10">
          {errorMessage && <p className="text-error">{errorMessage}</p>}
        </div>
        <div className="mx-auto mb-5 inline-flex items-center">
          <div className="w-20 border-b border-black" />
          <div className="mx-2">oder anmelden mit</div>
          <div className="w-20 border-b border-black" />
        </div>
        <div>
          <button
            className="outline-focus inline-flex items-center gap-3 rounded-md border border-black/30 px-6 py-3 hover:bg-[#f0f4f9] active:bg-[#e9edf2]"
            onClick={() => handleGoogleLogin(null)}
          >
            <IconGoogle /> Google Workspace
          </button>
        </div>
      </div>
    </div>
  );
};
