import { useEffect, useRef, useState } from 'react';

export interface SearchProps {
  id?: string;
  children?: React.ReactNode;
  onSearch?: (value: string) => void;
  onMenuOpen?: () => void;
  value?: string;
  placeholder?: string;
  width: 'full' | 'half';
}

export const Search = (props: SearchProps) => {
  const searchInputRef = useRef<HTMLInputElement>(null);

  const [advanced, setAdvanced] = useState(false);
  const [value, setValue] = useState('');

  const handler = (e: Event) => {
    if (e.target instanceof HTMLElement && !e.target.closest('.search')) {
      e.preventDefault();
      e.stopImmediatePropagation();
      setAdvanced(false);
    }
  };

  useEffect(() => {
    const keydownHandler = (e: KeyboardEvent) => {
      if (e.key === 'k' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        searchInputRef.current?.focus();
      }
      if (e.key === 'Escape') {
        setAdvanced(false);
      }
    };
    window.addEventListener('keydown', keydownHandler);
    return () => window.removeEventListener('keydown', keydownHandler);
  }, []);

  useEffect(() => {
    if (advanced) {
      window.document.addEventListener('click', handler, { capture: true });
    } else {
      window.document.removeEventListener('click', handler, { capture: true });
    }
    return () =>
      window.document.removeEventListener('click', handler, { capture: true });
  }, [advanced]);

  const generateBtn = (
    icon: string,
    onClick: () => void,
    className: string
  ) => {
    return (
      <button
        className={`absolute top-1 -mt-0.5 flex h-7 w-7 cursor-pointer items-center justify-center rounded-full text-black outline-none hover:bg-gray-200 focus:bg-gray-200 ${className}`}
        onClick={() => onClick()}
      >
        <span style={{ fontSize: '20px' }} className="material-icons">
          {icon}
        </span>
      </button>
    );
  };

  const onKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Escape') {
      onCancel();
    } else {
      props.onSearch?.((e.target as HTMLInputElement).value);
    }
  };

  const onCancel = () => {
    props.onSearch?.('');
    setValue('');
    searchInputRef.current?.focus();
  };

  const width =
    props.width === 'full' ? 'left-0 right-0' : 'left-1/4 right-1/4';
  return (
    <>
      <div
        className={`search absolute ${width} outline-focus flex items-center rounded outline-offset-2 focus-within:outline`}
        tabIndex={-1}
      >
        <input
          ref={searchInputRef}
          id={props.id}
          type="text"
          placeholder={props.placeholder}
          className="h-8 w-full rounded-md border border-gray-300 bg-white px-[38px] text-black placeholder-gray-400 placeholder:text-sm focus:outline-none"
          data-dd-privacy="allow"
          onKeyUp={onKeyUp.bind(this)}
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
        {value && generateBtn('close', onCancel.bind(this), 'right-9')}
        {props.children &&
          generateBtn('tune', setAdvanced.bind(this, !advanced), 'right-1')}

        {props.onMenuOpen && generateBtn('menu', props.onMenuOpen, 'left-1')}
        {!props.onMenuOpen &&
          generateBtn('search', () => props.onSearch?.(value), 'left-1')}
      </div>

      <div
        className={`search absolute ${width} top-8 overflow-hidden rounded-b-sm bg-white text-black shadow-lg ${
          advanced ? '' : 'max-h-0'
        }`}
      >
        <div className="mx-4 my-6 min-h-16 md:mx-8">{props.children}</div>
      </div>
    </>
  );
};
