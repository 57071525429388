import { Input } from '@kidsmanager/ui-core';
import { SelectableShiftSpec } from '../../roster-models';
import { useEffect, useRef, useState } from 'react';
import { nameToColor } from '@kidsmanager/util-common';

export interface EditableShiftSpecProps {
  spec: SelectableShiftSpec;
  onDeleted?: (index: number | undefined) => void;
  onBlur?: (spec: SelectableShiftSpec) => void;
}

export const EditableShiftSpec = (props: EditableShiftSpecProps) => {
  const [spec, setSpec] = useState<SelectableShiftSpec>();
  const ref = useRef<HTMLLIElement>(null);

  useEffect(() => {
    setSpec(props.spec);
  }, [props.spec]);

  const onChangeTitle = (value: string) => {
    if (!spec) return;
    setSpec({ ...spec, name: value, color: nameToColor(value) });
  };

  const onChangeStart = (value: string) => {
    if (!spec) return;
    setSpec({ ...spec, start: value });
  };
  const onChangeHrs = (value: string) => {
    if (!spec) return;
    setSpec({ ...spec, hrs: parseFloat(value) });
  };

  const handleBlur = (e: React.FocusEvent<HTMLElement>) => {
    if (!spec || ref.current?.contains(e.relatedTarget as Node)) return;
    spec.end = addHoursToStart(spec.start, spec.hrs);
    props.onBlur?.(spec);
  };

  return !spec ? undefined : (
    <li
      ref={ref}
      className="m-1 grid grid-cols-[20px_1fr_70px_70px_40px] items-center gap-3"
      onBlur={handleBlur.bind(this)}
    >
      <div
        className="h-6 w-6 rounded"
        style={{ backgroundColor: spec.color }}
      ></div>
      <Input value={spec.name} onChange={onChangeTitle.bind(this)} />
      <Input
        value={spec.start}
        mask="time"
        onChange={onChangeStart.bind(this)}
      />
      <Input
        value={spec.hrs.toString()}
        type="number"
        min={0}
        max={48}
        onChange={onChangeHrs.bind(this)}
      />
      <span
        className="material-icons cursor-pointer text-black/50"
        onClick={props.onDeleted?.bind(this, spec.index)}
      >
        delete
      </span>
    </li>
  );
};

const addHoursToStart = (start: string, hours: number) => {
  const [hrs, min] = start.split(':').map((x) => parseInt(x));
  const x = new Date(new Date().setHours(hrs, min) + hours * 60 * 60 * 1000);
  return `${x.getHours().toString().padStart(2, '0')}:${x.getMinutes().toString().padStart(2, '0')}`;
};
