import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { RosterStatusDay } from './components/roster-status-day';
import { RosterDay } from './components/roster-day';
import { DayData, SelectableShiftSpec, UserData } from '../roster-models';
import { RosterStatusUser } from './components/roster-status-user';
import { RosterColLabel } from './components/roster-col-label';
import { RosterRowLabel } from './components/roster-row-label';
import { RosterCursor, UnsetCursor } from './components/roster-cursor';
import {
  IMemberAssigments,
  IMembership,
  IMemberStats,
  ITeamConfig
} from '@kidsmanager/util-models';
import { buildUsers } from './helpers/user-builder';
import { ClientBackendContext } from '@kidsmanager/ui-api';

export interface ExtendedUserStats extends IMemberStats {
  holidays: number;
}

export interface RosterEditorProps {
  year: number;
  month: number;
  config: ITeamConfig | undefined;
  specs: SelectableShiftSpec[];
  members: IMembership[];
  stats: ExtendedUserStats[];
  plan: IMemberAssigments[];
  onChange?: () => void;
}

const updateDayStatus = (
  config: ITeamConfig,
  plan: IMemberAssigments[],
  durations: Map<string, number>,
  data: DayData[],
  day = -1
) => {
  const apply = (index: number) => {
    const hrs = plan.reduce((acc, user) => {
      const shifts = user.assigned[index]?.shifts;
      if (!shifts || !shifts.length) return acc;
      return (
        acc +
        shifts
          .map((id) => durations.get(id) || 0)
          .reduce((acc, val) => acc + val, 0)
      );
    }, 0);
    if (hrs >= config.fullCover) {
      data[index].cover = 'full';
    } else if (hrs >= config.minimalCover) {
      data[index].cover = 'minimal';
    } else {
      data[index].cover = undefined;
    }
  };
  if (day < 0) {
    for (let i = 0; i < data.length; i++) {
      apply(i);
    }
  } else {
    apply(day - 1);
  }
};

const updateUserStatus = (
  users: UserData[],
  plan: IMemberAssigments[],
  durations: Map<string, number>,
  userId: string | null = null
) => {
  const apply = (user?: UserData) => {
    const assigned = plan.find((a) => a.id === user?.id)?.assigned;
    if (!user || !assigned) return;
    user.available = assigned.reduce((acc, day) => {
      const hrs = day.shifts
        .map((id) => durations.get(id) || 0)
        .reduce((acc, val) => acc + val, 0);
      return acc - hrs;
    }, user.goal);
    user.warn =
      user.available < -15 ? 'high' : user.available < 0 ? 'low' : undefined;
  };
  if (userId === null) {
    //apply to all users
    users.forEach(apply);
  } else {
    //apply to specified user
    apply(users.find((u) => u.id === userId));
  }
};

export const RosterEditor = (props: RosterEditorProps) => {
  const table = useRef<HTMLTableElement>(null);
  const row_names = useRef<HTMLTableRowElement>(null);
  const [publicHols, setPublicHols] = useState<Date[]>([]);
  const [dates, setDates] = useState<DayData[]>([]);
  const [users, setUsers] = useState<UserData[]>([]);
  const [cursor, setCursor] = useState(UnsetCursor);

  const client = useContext(ClientBackendContext);

  const durations = useMemo(() => {
    const dict: Map<string, number> = new Map();
    props.specs.forEach((spec) => {
      if ((spec.index || 0) < 0) return;
      dict.set(spec.id, spec.hrs);
    });
    return dict;
  }, [props.specs]);

  useEffect(() => {
    if (!client) return;
    client.holiday.publicHolidays(props.year).then(setPublicHols);
  }, [client, props.year]);

  useEffect(() => {
    if (!props.config || !users.length) {
      return;
    }
    const data: DayData[] = [];
    const daysInMonth = new Date(props.year, props.month, 0).getDate();

    for (let i = 0; i < daysInMonth; i++) {
      const date = new Date(props.year, props.month - 1, i + 1);
      data.push({
        date,
        day: i + 1,
        workday: date.getDay() !== 0 && date.getDay() !== 6
      });
    }
    updateDayStatus(props.config, props.plan, durations, data);
    updateUserStatus(users, props.plan, durations);
    setDates(data);
  }, [
    props.month,
    props.year,
    durations,
    users,
    props.config,
    props.plan,
    props.stats
  ]);

  useEffect(() => {
    setUsers(
      buildUsers(
        props.members,
        props.stats,
        publicHols,
        props.year,
        props.month
      )
    );
  }, [props.members, props.year, props.month, props.stats, publicHols]);

  const handleMouseOver = (e: React.MouseEvent) => {
    if (!table.current || !row_names.current) {
      return;
    }
    const tableRect = table.current.getBoundingClientRect();
    const cell = e.target as HTMLTableCellElement;
    const { top, left, width, height } = cell.getBoundingClientRect();

    const x = left - tableRect.left + width / 2;
    const y = top - tableRect.top + height / 2;
    const xoffset = 60;
    const yoffset = row_names.current.getBoundingClientRect().height;
    setCursor({ x, y, yoffset, xoffset });
  };

  const handleOnChange = (day: number, userId: string) => {
    if (!props.config) return;
    updateDayStatus(props.config, props.plan, durations, dates, day);
    updateUserStatus(users, props.plan, durations, userId);

    setTimeout(() => setDates([...dates]), 0);
    props.onChange?.();
  };

  return (
    <div className="relative mb-10 flex-1 rounded-md">
      <RosterCursor {...cursor} />
      <table ref={table}>
        <thead className="sticky top-5 z-10 bg-white/80 backdrop-blur-md">
          <tr ref={row_names}>
            <th></th>
            {users.map((user) => (
              <th
                key={user.id}
                className="relative h-20 text-nowrap font-normal"
              >
                <RosterColLabel>{user.name}</RosterColLabel>
              </th>
            ))}
            <th></th>
          </tr>
          <tr>
            <td></td>
            {users.map((user) => (
              <td key={`status-${user.id}`}>
                <RosterStatusUser
                  user={user}
                  year={props.year}
                  month={props.month}
                />
              </td>
            ))}
            <td></td>
          </tr>
        </thead>
        <tbody>
          {dates.map((data, day) => (
            <tr key={data.day}>
              <td>
                <RosterRowLabel date={data.date} />
              </td>

              {users.map((user, userIndex) => (
                <td
                  key={`${data.day}-${user.id}`}
                  className={`border border-neutral-200/80 ${data.workday ? '' : 'bg-neutral-100/70'}`}
                  onMouseEnter={handleMouseOver.bind(this)}
                >
                  <RosterDay
                    value={props.plan[userIndex]?.assigned[day]}
                    user={user}
                    day={data}
                    specs={props.specs}
                    onChange={handleOnChange.bind(this)}
                  />
                </td>
              ))}
              <td>
                <RosterStatusDay cover={data.cover} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
