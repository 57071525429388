import './load-spinner.css';

export interface LoadSpinnerProps {
  show: boolean;
  children?: React.ReactNode;
}

export const LoadSpinner = (props: LoadSpinnerProps) => {
  return (
    <div className="relative inline-block">
      <div className="absolute top-1/2 -translate-x-full -translate-y-1/2">
        <div
          className="load-spinner text-accent"
          style={{ display: props.show ? 'block' : 'none' }}
        >
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
      {props.children}
    </div>
  );
};
