import { Link, useLocation } from 'react-router-dom';
import { IUser } from '@kidsmanager/util-models';

export interface DetailHeaderProperties {
  user?: IUser;
  children?: React.ReactNode;
}

export const DetailHeader = (props: DetailHeaderProperties) => {
  const location = useLocation();
  const currentPath = location.pathname.split('/').pop();
  const tabs = [
    { path: 'profile', title: 'Profil' },
    { path: 'permissions', title: 'Berechtigungen' },
    { path: 'shifts', title: 'Dienste' }
  ];

  return (
    <div className="relative">
      <div className="mb-4">
        <h1 className="mb-4 text-2xl font-semibold">{props.user?.name}</h1>
        <div className="flex space-x-6">
          {tabs.map((tab) => (
            <Link
              key={tab.title}
              to={`${tab.path}`}
              className={`px-6 py-1 ${
                currentPath === tab.path
                  ? 'border-accent border-b-2'
                  : 'border-b-2 border-transparent'
              } transition-colors hover:bg-gray-300`}
            >
              {tab.title}
            </Link>
          ))}
        </div>
        <Link
          to="/admin/users"
          className="absolute right-0 top-0 -mt-4 text-4xl text-gray-600 hover:text-black"
          aria-label="Close"
        >
          &times;
        </Link>
      </div>
      <div>{props.children}</div>
    </div>
  );
};
