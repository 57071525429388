import { Route } from 'react-router-dom';
import { FeatureLogin, FeatureGoogleCallback, FeatureLogout } from '@kidsmanager/feature-account';
import {
  AdminRosters,
  AdminRosterGeneral,
  AdminUserDetailPermissions,
  AdminUserDetailProfile,
  AdminUserDetailShifts,
  AdminUsers,
  AdminRosterRules,
  AdminRosterNonWork
} from '@kidsmanager/feature-admin';
import { TeamDashboard } from '@kidsmanager/feature-team';
import { Roster } from '@kidsmanager/feature-roster';
import { JournalDashboard } from '@kidsmanager/feature-journal';
import { AppGuard } from './app-guard';
import { PageNotFound } from './page-not-found';

export const AppRoutes = (): React.ReactNode => {
  return (
    <>
      <Route path="/login" element={<FeatureLogin brand="kidsmgr" linkWithLegacy />} />
      <Route path="/logout" element={<FeatureLogout unlinkWithLegacy />} />
      <Route path="/google-callback" element={<FeatureGoogleCallback />} />
      <Route element={<AppGuard />}>
        <Route path="/" element={<JournalDashboard />} />
        <Route path="/team/:group" element={<TeamDashboard />}>
          <Route path="/team/:group/roster/:month?" element={<Roster />} />
        </Route>
      </Route>
      <Route path="/admin" element={<AppGuard requires="admin" />}>
        <Route path="users/:id?" element={<AdminUsers />}>
          <Route index path="profile" element={<AdminUserDetailProfile />} />
          <Route path="permissions" element={<AdminUserDetailPermissions />} />
          <Route path="shifts" element={<AdminUserDetailShifts />} />
        </Route>
        <Route path="roster/:id?" element={<AdminRosters />}>
          <Route path="" element={<AdminRosterGeneral />} />
          <Route path="rules" element={<AdminRosterRules />} />
          <Route path="non-work" element={<AdminRosterNonWork />} />
        </Route>
      </Route>
      <Route path="*" element={<PageNotFound />} />
    </>
  );
};
