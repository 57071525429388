import { IEntry, ISaveEntry } from '@kidsmanager/util-models';
import { IAuth, fetch } from '../auth';

export class ApiTimesheetLog {
  constructor(
    private auth: IAuth,
    private fetch: fetch,
    private clearParentCache: (key: string) => void
  ) {}

  async create(from: Date, entry: ISaveEntry, userId?: string) {
    userId = userId || 'my';
    this.clearParentCache(`worked-${userId}-*`);
    return this.fetch(
      `/api/work/${userId}/subshift/${from.toLocaleISODate()}/${from.toApiSeconds()}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(entry)
      }
    );
  }

  async update(value: IEntry, userId?: string) {
    userId = userId || 'my';
    this.clearParentCache(`worked-${userId}-*`);
    //TODO: consider how we avoid this conversion and align the create/update methods
    const entry: ISaveEntry = {
      shiftId: value.shiftId,
      duration: value.durationSeconds,
      breakDuration: value.breakDurationSeconds,
      index: value.index,
      total: value.total,
      label: value.label,
      note: value.note,
      tag: value.tag,
      type: value.type,
      code: value.code,
      infoOnly: value.infoOnly
    };
    return this.fetch(
      `/api/work/${userId}/subshift/${value.date}/${value.startSeconds}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(entry)
      }
    );
  }

  async delete(day: string, start: number, userId?: string) {
    userId = userId || 'my';
    this.clearParentCache(`worked-${userId}-*`);
    return this.fetch(`/api/work/${userId}/subshift/${day}/${start}`, {
      method: 'DELETE'
    });
  }
}
