import { ContextMenuButton, SplitButton } from '@kidsmanager/ui-core';
import { IReleasedPlan } from '@kidsmanager/util-models';
import { useEffect, useState } from 'react';

export type VersionState = 'empty' | 'unsaved' | 'saved' | 'released';

export interface RosterVersionerProps {
  year: number;
  month: number;
  state: VersionState;
  releases: IReleasedPlan[];
  onRelease?: () => void;
  onUnpublish?: (version: string) => void;
  onDiscard?: (version: string) => void;
  onSave?: () => void;
  onCancel?: () => void;
  onOpen?: (version: string) => void;
}

export const RosterVersioner = (props: RosterVersionerProps) => {
  const [releases, setReleases] = useState<
    { label: string; release: IReleasedPlan }[]
  >([]);

  useEffect(() => {
    const values = [...props.releases];
    values.sort((a, b) => (b.releasedAt > a.releasedAt ? 1 : -1));

    setReleases(
      values.map((release) => {
        const day = new Date(release.releasedAt);
        const date = `${day.toLocaleString('de-DE', { year: 'numeric', month: '2-digit', day: '2-digit' })}`;
        const time = `${day.toLocaleString('de-DE', { hour: '2-digit', minute: '2-digit' })}`;
        return { label: `${date} @ ${time}`, release };
      })
    );
  }, [props.releases, props.state]);

  const handleContextMenu = (key: string, release: IReleasedPlan) => {
    switch (key) {
      case 'open':
        props.onOpen?.(release.version);
        break;
      case 'discard':
        props.onDiscard?.(release.version);
        break;
      case 'unpublish':
        props.onUnpublish?.(release.version);
        break;
    }
  };

  const handleDraftAction = (key?: string) => {
    switch (key) {
      case 'save':
        props.onSave?.();
        break;
      case 'cancel':
        props.onCancel?.();
        break;
      case 'discard':
        props.onDiscard?.('draft');
        break;
      case 'release':
        props.onRelease?.();
        break;
    }
  };

  return (
    <div className="mb-10">
      {props.state !== 'empty' && (
        <div className="mt-8">
          <h2 className="font-semibold">Versionen</h2>
          <ul>
            {props.state === 'unsaved' && (
              <li className="mb-4 flex items-center leading-10">
                <span className="flex-1">Entwurf</span>
                <SplitButton
                  onClick={handleDraftAction.bind(this)}
                  primaryAction="save"
                  options={[
                    { key: 'save', label: 'Speichern' },
                    { key: 'cancel', label: 'Abbrechen' }
                  ]}
                >
                  Speichern
                </SplitButton>
              </li>
            )}
            {props.state === 'saved' && (
              <li className="mb-4 flex items-center leading-10">
                <span className="flex-1">Entwurf</span>
                <SplitButton
                  onClick={handleDraftAction.bind(this)}
                  primaryAction="release"
                  options={[
                    { key: 'release', label: 'Freigeben' },
                    { key: 'discard', label: 'Verwerfen' }
                  ]}
                >
                  Freigeben
                </SplitButton>
              </li>
            )}
            {releases.map((item, index) => (
              <li key={index} className="mb-2 flex items-center">
                <span className="flex-1">{item.label}</span>
                <ContextMenuButton
                  disabled={props.state === 'unsaved'}
                  options={[
                    { key: 'open', label: 'Offnen' },
                    index === 0
                      ? { key: 'unpublish', label: 'Zurücksetzen' }
                      : { key: 'discard', label: 'Verwerfen' }
                  ]}
                  onChange={(e) => handleContextMenu(e, item.release)}
                />
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};
