import {
  OtpExchangeResponse,
  TokenExchangeResponse
} from '@kidsmanager/util-models';
import { UserNotFoundException } from '@kidsmanager/util-common';
import { IAuth } from './auth';

type fetch = (url: string, init?: RequestInit) => Promise<Response>;

export class ApiOauth2 {
  constructor(
    private auth: IAuth,
    private fetch: fetch
  ) {}

  async googleWorkspaceUrl(
    role: 'USER' | 'ADMIN',
    tenant: string | null,
    origin: string,
    googleEmail?: string | null
  ): Promise<{ url: string }> {
    const email = googleEmail || this.auth.email();
    const params = new URLSearchParams({
      idp: 'google',
      origin,
      ...(role === 'ADMIN' && { type: 'elevate' }),
      ...(email && { email }),
      ...(tenant && { tenant })
    });
    const url = `/oauth2/authUrl?${params.toString()}`;
    return await (await this.fetch(url)).json();
  }

  async verify(
    code: string,
    origin: string,
    tenant: string | null
  ): Promise<TokenExchangeResponse | undefined> {
    const params = new URLSearchParams({
      idp: 'google',
      origin,
      code: code,
      ...(tenant && { tenant })
    });

    const url = `/oauth2/verify?${params.toString()}`;
    const response = await await this.fetch(url);
    const responseJson = await response.json();
    if (response.ok) {
      return responseJson;
    } else if (response.status === 404) {
      throw new UserNotFoundException(
        responseJson.message,
        responseJson.email,
        responseJson.tenant,
        404
      );
    }
  }

  async link(): Promise<OtpExchangeResponse> {
    const url = '/oauth2/link';
    return await (await this.fetch(url, { method: 'POST' })).json();
  }

  async setEmailAddress(email: string) {
    const url = '/oauth2/email';
    return await (
      await this.fetch(url, { method: 'POST', body: JSON.stringify({ email }) })
    ).json();
  }
}
