import { useContext, useEffect, useState } from 'react';
import { ClientBackendContext } from '@kidsmanager/ui-api';
import { emptyAccessMap, IAccessMap } from '@kidsmanager/util-models';
import { Header } from '@kidsmanager/ui-core';
import { useNavigate } from 'react-router-dom';

export const PageNotFound = () => {
  const client = useContext(ClientBackendContext);
  const navigate = useNavigate();
  const [accessMap, setAccessMap] = useState<IAccessMap>(emptyAccessMap);

  useEffect(() => {
    if (client.auth.isAuthenticated()) {
      client.settings.accessMap().then(setAccessMap);
    } else {
      navigate('/login');
    }
  }, [client, navigate]);

  return (
    <>
      <Header {...accessMap} />
      <div className="mx-auto mt-40 max-w-md text-center">
        <h1 className="text-2xl">404 - Seite nicht gefunden</h1>
        <p>
          Die Seite, die du suchst, existiert nicht. Überprüfe die URL oder
          kehre zur Startseite zurück.
        </p>
      </div>
    </>
  );
};
