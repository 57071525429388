import { Link } from 'react-router-dom';

export interface HeaderAreasProps {
  areas: { title: string; href: string }[];
}

export const HeaderAreas = (props: HeaderAreasProps) => {
  const isSelected = (area: { href: string }) => {
    const path = area.href.replace(window.location.origin, '');
    if (path === window.location.pathname) {
      return true;
    }
    if (path.includes('index')) {
      // This is a workaround for the legacy project areas...
      // it avoids highlighting all template groups when one is selected
      return false;
    }
    const [selected] = path.split('/').filter((x) => x);
    return selected && window.location.pathname.startsWith(`/${selected}`);
  };

  return (
    <ul className="flex-1">
      {props.areas.map((area) => (
        <li
          key={area.title}
          className="border-r-silver-400 inline-block border-r"
          style={{
            backgroundColor: isSelected(area) ? '#ddd' : undefined
          }}
        >
          <Link
            className="text-hdr text-silver-900 outline-focus inline-block pl-[10px] pr-[30px] leading-7 -outline-offset-1"
            to={area.href}
          >
            {area.title}
          </Link>
        </li>
      ))}
    </ul>
  );
};
