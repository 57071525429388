import { Role } from '@kidsmanager/util-storage';

interface JwtPayload {
  sub: string;
  scope: string;
  email_verified: boolean;
  iss: string;
  origin_jti: string;
  aud: string;
  event_id: string;
  token_use: string;
  auth_time: number;
  exp: number;
  iat: number;
  email: string;
  displayName: string;
  idp?: string;
  roles: Role[];
}

export class Jwt {
  constructor(
    public token: string,
    private payload?: JwtPayload
  ) {
    if (!token || token === '') {
      this.payload = undefined;
    } else {
      this.payload = this.decodeBase64Payload(token.split('.')[1]);
    }
  }

  public expiresAt(): number {
    return this.payload ? this.payload.exp * 1000 : 0;
  }

  public email(): string {
    return this.payload ? this.payload.email : '';
  }

  public userId(): string {
    return this.payload ? this.payload.sub : '';
  }

  public valid(): boolean {
    return this.payload ? this.payload.exp > Date.now() / 1000 : false;
  }

  private decodeBase64Payload = (input: string): JwtPayload => {
    //assumed no padding is added, may need to remove == from end if padding used
    let bitStore = 0;
    const output = Array.from(input).reduce(
      (acc: string, encoding: string, index: number) => {
        const value =
          'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/'.indexOf(
            encoding
          );
        bitStore = index % 4 ? bitStore * 64 + value : value;
        // skip first of each 4 chars
        if (index++ % 4) {
          // first 8 bits to an ASCII character.
          acc += String.fromCharCode(0xff & (bitStore >> ((-2 * index) & 6)));
        }
        return acc;
      },
      ''
    );
    return JSON.parse(output);
  };

  tenant() {
    if (!this.payload) {
      return '';
    }
    const index = this.payload.iss.lastIndexOf('/');
    return this.payload.iss.substring(index + 1);
  }

  displayName() {
    return this.payload ? this.payload.displayName : '';
  }

  idp() {
    return this.payload?.idp || '';
  }

  inRole(role: Role): boolean {
    if (!this.payload) {
      return false;
    }
    return this.payload.roles.includes(role);
  }

  hasScope(scope: string): boolean {
    if (!this.payload) {
      return false;
    }
    return this.payload.scope.includes(scope);
  }
}
