import { ClientBackendContext } from '@kidsmanager/ui-api';
import { IUser } from '@kidsmanager/util-models';
import {
  useContext,
  useEffect,
  useState,
  MouseEvent as ReactMouseEvent
} from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { DetailHeader } from './components/detail-header';
import { UserCard } from './components/user-card';
import { Input, LinkButton, Search } from '@kidsmanager/ui-core';

export const AdminUsers = () => {
  const client = useContext(ClientBackendContext);
  const [, setGoogleUserEmails] = useState<string[]>([]); // needed for hinzufuegen
  const [users, setUsers] = useState<IUser[]>();
  const [selectedUser, setSelectedUser] = useState<IUser>();
  const params = useParams<{ id: string }>();

  const [dividerPosition, setDividerPosition] = useState(
    (-1 * window.innerWidth) / 2
  );
  const [isResizing, setIsResizing] = useState(false);

  useEffect(() => {
    if (client.auth.idp() === 'GOOGLE') {
      client.admin.users.google().then((users) => {
        if (!users) {
          return;
        }
        setGoogleUserEmails(users);
      });
    }
    client.admin.users.all().then((users) => {
      if (users) {
        setUsers(users);
      }
    });
  }, [client]);

  useEffect(() => {
    // ensure positive dividerposition if id is set to sho details OR negative dividerposition if not set to hide details
    if (params.id) {
      setDividerPosition((prev) => (prev < 0 ? prev * -1 : prev));
    } else {
      setDividerPosition((prev) => (prev < 0 ? prev : prev * -1));
    }
  }, [params]);

  useEffect(() => {
    if (params.id && users) {
      setSelectedUser(users.find((u) => u.userId === params.id));
    } else {
      setSelectedUser(undefined);
    }
  }, [users, params]);

  const isDetailVisible = dividerPosition > -1;

  const handleMouseMove = (e: MouseEvent) => {
    const x = e.clientX;
    if (
      isDetailVisible &&
      window.innerWidth * 0.2 < x &&
      x < window.innerWidth * 0.8
    ) {
      setDividerPosition(x);
    }
  };

  const handleMouseUp = () => {
    setIsResizing(false);
    window.removeEventListener('mousemove', handleMouseMove);
    window.removeEventListener('mouseup', handleMouseUp);
  };

  const handleMouseDown = (e: ReactMouseEvent) => {
    e.preventDefault();
    setIsResizing(true);
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);
  };

  return (
    <div className="flex min-h-screen w-full">
      {/* Master div */}
      <div
        className={`p-4 ${isResizing ? '' : 'transition-all duration-300 ease-in-out'} ${isDetailVisible ? 'flex-shrink-0' : 'flex-grow'}`}
        style={{ flexBasis: isDetailVisible ? `${dividerPosition}px` : '100%' }}
      >
        <div className="flex flex-col items-center justify-center">
          <div className="relative h-8 w-full">
            <Search
              id="kidsmgr-user"
              placeholder="Filter nach Name..."
              width="half"
            >
              <div>Advanced goes here..</div>
            </Search>
          </div>
          <div className="m-2 flex w-full justify-between px-4">
            <span>{users?.length || 0} BenutzerInnen</span>
            <LinkButton>+ hinzuf&uuml;gen</LinkButton>
          </div>
          <div className="flex flex-wrap gap-2 p-4">
            {users?.map((user, index) => (
              <div key={user.userId}>
                <UserCard
                  name={user?.lastName || ''}
                  firstName={user?.firstName || ''}
                  userId={user.userId || ''}
                  selected={user.userId === selectedUser?.userId}
                />
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* divider */}
      {isDetailVisible && (
        <div
          className="w-1 cursor-ew-resize bg-neutral-100"
          onMouseDown={handleMouseDown}
        ></div>
      )}

      {/* details outlet */}
      <div
        className={`overflow-auto bg-neutral-50 ${isResizing ? '' : 'transition-all duration-300 ease-in-out'} ${isDetailVisible ? 'flex-grow p-4 opacity-100' : 'w-0 opacity-0'}`}
        style={{
          flexBasis: isDetailVisible ? `calc(100% - ${dividerPosition}px)` : '0'
        }}
      >
        {selectedUser && (
          <DetailHeader user={selectedUser}>
            <Outlet />
          </DetailHeader>
        )}
      </div>
    </div>
  );
};
