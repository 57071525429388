import { useMemo } from 'react';

export interface BadgeProps {
  name: string;
  color: string;
  symbol?: string;
  count?: number;
  size?: 'sm' | 'md';
  infoOnly?: boolean;
  onClick?: (e: React.MouseEvent) => void;
}

export const Badge = (props: BadgeProps) => {
  const textColor = useMemo(() => {
    const r = parseInt(props.color.substring(1, 3), 16);
    const g = parseInt(props.color.substring(3, 5), 16);
    const b = parseInt(props.color.substring(5, 7), 16);
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
    return luminance > 0.6 ? '#000000cc' : '#ffffffee';
  }, [props.color]);

  return (
    <div
      className={`${props.size === 'sm' ? 'w-6 text-xs leading-6' : 'w-8'} rounded-full text-center`}
      style={{
        opacity: props.infoOnly ? 0.8 : 1,
        backgroundColor: props.color,
        color: textColor,
        userSelect: 'none'
      }}
      onClick={props.onClick}
    >
      {props.symbol || '?'}
    </div>
  );
};
