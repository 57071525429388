import { IconButton } from './icon-button';

export interface ContextMenuButtonProps {
  disabled?: boolean;
  options: { key: string; label: string }[];
  onChange?: (value: string) => void;
}

export const ContextMenuButton = (props: ContextMenuButtonProps) => {
  const handleClick = (key: string) => {
    if (props.onChange) {
      props.onChange(key);
    }
    (document.activeElement as HTMLElement)?.blur();
  };

  return (
    <div className="">
      <div
        tabIndex={0}
        className={`outline-focus relative inline-flex cursor-pointer items-center outline-offset-2 ${!props.disabled && 'group'}`}
      >
        <IconButton disabled={props.disabled}>more_vert</IconButton>
        <ul className="shadow-hdr border-silver-300 absolute right-0 top-8 z-50 hidden border bg-white py-2 text-left group-focus-within:block">
          {props.options.map((option) => (
            <li
              key={option.key}
              className="min-w-14 text-nowrap px-4 py-2 text-sm hover:bg-stone-200"
              onClick={handleClick.bind(this, option.key)}
            >
              {option.label}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
