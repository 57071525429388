export const JournalDashboard = () => {
  //HACK: required prior to full journal migration
  if (window.location.pathname.length <= 1) {
    const tenant = localStorage.getItem('tenant');
    if (tenant && tenant !== 'dev' && tenant !== 'demo') {
      window.location.replace(`https://${tenant}.kidsmanager.at`);
      return (
        <div className="mt-44 text-center text-2xl">
          Ihre Daten werden geladen...
        </div>
      );
    }
  }
  return <div className="p-10"> This is where we have our journal </div>;
};
