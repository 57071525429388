import React from 'react';
import { ClientBackend } from './client-backend';
import { IAuth } from './auth';

export const ClientBackendContext = React.createContext(
  new ClientBackend({} as IAuth)
);

export interface ClientBackendProviderProps {
  children: React.ReactNode;
  auth: IAuth;
}

export function ClientBackendProvider(props: ClientBackendProviderProps) {
  const client = new ClientBackend(props.auth);
  return (
    <ClientBackendContext.Provider value={client}>
      {props.children}
    </ClientBackendContext.Provider>
  );
}
