import { ClientBackendContext } from '@kidsmanager/ui-api';
import { LinkButton, Tabs } from '@kidsmanager/ui-core';
import { IRosterTemplate } from '@kidsmanager/util-models';
import { useContext, useEffect, useState } from 'react';
import { Link, Outlet, useParams } from 'react-router-dom';

export const AdminRosters = () => {
  const params = useParams<{ id: string }>();
  const client = useContext(ClientBackendContext);
  const [templates, setTemplates] = useState<IRosterTemplate[]>([]);
  const [selected, setSelected] = useState<IRosterTemplate>();

  useEffect(() => {
    client.admin.roster.templates().then((temmplates) => {
      setTemplates(temmplates);
      setSelected(temmplates.find((t) => t.id === params.id) || temmplates[0]);
    });
  }, [client, params.id]);

  const tabs = [
    { path: '', title: 'Allgemein' },
    { path: 'rules', title: 'Regeln' },
    { path: 'non-work', title: 'Nichtleistungszeiten' }
  ];

  return (
    <div className="grid grid-cols-[25%_auto]">
      <div className="px-4 py-6">
        <h1 className="mb-2">Dienstvorlagen</h1>
        <ul className="border-silver-200 min-h-64 border py-2">
          {templates.map((template) => (
            <li
              key={template.id}
              style={{
                backgroundColor:
                  selected?.id === template.id ? 'var(--color-accent)' : '',
                color: selected?.id === template.id ? 'white' : ''
              }}
            >
              <Link
                className="block w-full px-3 leading-8"
                to={`/admin/roster/${template.id}`}
              >
                {template.name}
              </Link>
            </li>
          ))}
        </ul>
        <div className="my-1 text-right">
          <LinkButton>+ hinzuf&uuml;gen</LinkButton>
        </div>
      </div>
      <div className="border-silver-100 min-h-[100vh] border-l-4 bg-neutral-100">
        <Tabs basePath="/admin/roster" tabs={tabs}>
          <Outlet context={[selected]} />
        </Tabs>
      </div>
    </div>
  );
};
