import { Link } from 'react-router-dom';

export interface UserCardProperties {
  userId: string;
  name: string;
  firstName: string;
  selected: boolean;
}

export const UserCard = (props: UserCardProperties) => {
  return (
    <Link
      to={`/admin/users/${props.userId}/profile`}
      className={`flex h-16 w-48 flex-col items-center justify-center rounded-lg ${props.selected ? 'bg-accent text-white' : 'bg-primary'}`}
    >
      <span className="font-medium">{props.name}</span>
      <span className="text-sm">{props.firstName}</span>
    </Link>
  );
};
