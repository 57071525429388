import { IShiftSpec } from '@kidsmanager/util-models';
import { SelectableShiftSpec } from '../../roster-models';
import { symbolize } from '@kidsmanager/util-common';

export const KeyHoliday = '-holiday-';
export const KeyUnavailable = '-unavailable-';

export const standardShifts = (): SelectableShiftSpec[] => {
  return [
    {
      index: -2,
      id: KeyHoliday,
      name: 'Urlaub',
      color: '#e0e0e0',
      symbol: 'U',
      start: '00:00',
      end: '23:59',
      hrs: 24,
      infoOnly: true,
      tip: 'Urlaub muss vom Teammitglied hinzugefügt werden und kann hier nicht eingetragen werden.',
      readonly: true,
      created: '2020-01'
    },
    {
      index: -1,
      id: KeyUnavailable,
      name: 'Nicht verfügbar',
      color: '#e0e0e0',
      symbol: 'X',
      start: '00:00',
      end: '23:59',
      hrs: 24,
      readonly: true,
      created: '2020-01'
    }
  ];
};

export const compileShifts = (
  shifts: IShiftSpec[],
  standard: SelectableShiftSpec[]
): SelectableShiftSpec[] => {
  const clone: SelectableShiftSpec[] = structuredClone(shifts);
  symbolize(clone);
  return [...standard, ...clone];
};
