export const applyBlur = (
  value: string,
  type: 'text' | 'number' | 'password' | undefined,
  min: number | undefined,
  max: number | undefined,
  mask: 'time' | undefined
) => {
  if (mask === 'time') {
    return applyTimeBlur(value);
  }
  if (type === 'number') {
    const num = parseFloat(value);
    if (isNaN(num)) {
      return '';
    }
    if (min !== undefined && num < min) {
      return min.toString();
    }
    if (max !== undefined && num > max) {
      return max.toString();
    }
  }
  return value;
};

const applyTimeBlur = (value: string) => {
  const time = value.replace(/[^0-9:]/g, '');

  const extractHoursMins = (value: string) => {
    if (value.indexOf(':') === -1 && value.length > 2) {
      switch (value.length) {
        case 3:
          return [value.slice(0, 1), value.slice(1)];
        default:
          return [value.slice(0, 2), value.slice(2)];
      }
    } else {
      return value.split(':');
    }
  };

  const [hours, minutes] = extractHoursMins(time);
  const hoursInt = parseInt(hours);
  const minutesInt = parseInt(minutes);
  const h = isNaN(hoursInt) ? 0 : Math.min(23, hoursInt);
  const m = isNaN(minutesInt) ? 0 : Math.min(59, minutesInt);
  return `${h.toString().padStart(2, '0')}:${m.toString().padStart(2, '0')}`;
};
