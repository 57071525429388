import { IconButton } from '@kidsmanager/ui-core';
import { IRosterTemplateShift } from '@kidsmanager/util-models';

export interface ShiftPatternsProps {
  patterns: IRosterTemplateShift[];
}

export const ShiftPatterns = (props: ShiftPatternsProps) => {
  return (
    <label className="form-control w-full">
      <div className="label">
        <span className="text-sm font-medium">Dienstmuster</span>
      </div>
      <div className="border-silver-200 relative min-h-44 rounded border bg-white p-4">
        <table className="mb-8 w-full border-separate border-spacing-y-1">
          <thead className="text-left text-xs">
            <tr>
              <th>Name</th>
              <th>Start</th>
              <th>Dauer</th>
              <th className="w-10"></th>
            </tr>
          </thead>
          <tbody>
            {props.patterns.map((pattern) => (
              <tr key={pattern.id} className="odd:bg-neutral-100">
                <td className="pl-2">{pattern.name}</td>
                <td>{pattern.start}</td>
                <td>{pattern.duration}</td>
                <td className="text-center">
                  <IconButton>more_vert</IconButton>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="absolute bottom-0 right-1">
          <IconButton color="ghost">add</IconButton>
        </div>
      </div>
    </label>
  );
};
