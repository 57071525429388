import { IRosterTemplate } from '@kidsmanager/util-models';
import { IAuth, fetch } from '../auth';
import { BackendCache } from '../backend-cache';

const cache = new BackendCache();

export class ApiAdminRosters {
  constructor(
    private auth: IAuth,
    private fetch: fetch
  ) {}

  async templates(): Promise<IRosterTemplate[]> {
    return [
      {
        id: '1',
        name: 'WGs',
        shiftPatterns: [
          { id: '1', name: 'Hauptdienst', start: '13:00', duration: 25 },
          { id: '2', name: 'Beidienst', start: '16:00', duration: 8 }
        ],
        saveAs: [
          {
            id: '1',
            name: 'Krankenstand',
            description: 'Nicht geplant (Zeit Eintrag laut Dienstplan)',
            abbreviation: 'KS',
            infoOnly: false
          },
          {
            id: '2',
            name: 'Flex 10',
            description: 'Dienst verlängert (3 Tage vorher nicht bekannt)',
            abbreviation: 'F10',
            infoOnly: false
          }
        ]
      },
      {
        id: '2',
        name: 'Zentrale',
        shiftPatterns: [],
        saveAs: []
      }
    ];
  }
}
