import { Button, GhostButton, Input } from '@kidsmanager/ui-core';
import { IRosterTemplate } from '@kidsmanager/util-models';
import { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { ShiftPatterns } from './components/shift-patterns';
import { SaveAs } from './components/save-as';

export const AdminRosterGeneral = () => {
  const [template] = useOutletContext() as [template: IRosterTemplate];
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    setIsDirty(false);
  }, [template]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsDirty(false);
  };

  const handleReset = (event: React.FormEvent<HTMLFormElement>) => {
    setIsDirty(false);
  };

  return (
    template && (
      <form
        key={template.id}
        className="flex flex-col gap-4"
        onChange={setIsDirty.bind(this, true)}
        onReset={handleReset.bind(this)}
        onSubmit={handleSubmit.bind(this)}
      >
        <Input name="name" label="Name" defaultValue={template.name} />
        <ShiftPatterns patterns={template.shiftPatterns} />
        <SaveAs options={template.saveAs} />
        <div className="my-4 flex gap-2">
          <GhostButton type="reset" disabled={!isDirty}>
            Abbrechen
          </GhostButton>
          <Button type="submit" disabled={!isDirty}>
            Speichern
          </Button>
        </div>
      </form>
    )
  );
};
