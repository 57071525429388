import {
  IHolidayAllowance,
  IHolidayBooking,
  IUserBookings
} from '@kidsmanager/util-models';
import { IAuth, fetch } from '../auth';
import { BackendCache } from '../backend-cache';

const cache = new BackendCache();

export class ApiHoliday {
  constructor(
    private auth: IAuth,
    private fetch: fetch
  ) {}

  async publicHolidays(...years: number[]): Promise<Date[]> {
    years.sort();
    return cache.get(`holidays-${years.join('-')}`, async () => {
      const values = await Promise.all(
        years.map((year) => this.fetch(`/api/holiday?type=public&year=${year}`))
      );

      return (
        await Promise.all(
          values.map(async (value) => {
            return value.json();
          })
        )
      )
        .flat()
        .map((x) => new Date(x));
    });
  }

  async allowances(userId?: string): Promise<IHolidayAllowance[]> {
    return cache.get(`holidays-${userId}`, async () => {
      const response = await this.fetch(
        `/api/holiday/${userId ? userId : 'my'}/allowances`
      );
      return await response.json();
    });
  }

  async bookings(userId?: string): Promise<IHolidayBooking[]> {
    userId = userId || 'my';
    return cache.get<IHolidayBooking[]>(`bookings-${userId}`, async () => {
      const response = await this.fetch(`/api/holiday/${userId}/bookings`);
      return (await response.json()).map((booking: IHolidayBooking) => ({
        ...booking,
        from: new Date(booking.from),
        to: new Date(booking.to)
      }));
    });
  }

  async bookingsForYear(
    year: number,
    userId?: string
  ): Promise<IHolidayBooking[]> {
    userId = userId || 'my';
    return cache.get<IHolidayBooking[]>(
      `bookings-${userId}-${year}`,
      async () => {
        const response = await this.fetch(
          `/api/holiday/${userId}/bookings?year=${year}`
        );
        return (await response.json()).map((booking: IHolidayBooking) => ({
          ...booking,
          from: new Date(booking.from),
          to: new Date(booking.to)
        }));
      }
    );
  }

  async booking(
    start: Date,
    userId?: string
  ): Promise<IHolidayBooking | undefined> {
    const all = await this.bookings(userId);
    return all.find(
      (booking) => booking.from.toISOString() === start.toISOString()
    );
  }

  async request(from: Date, to?: Date): Promise<void> {
    cache.clear(`bookings-${undefined}`);
    await this.fetch(`/api/holiday/booking/${from.toLocaleISODate()}`, {
      method: 'PUT',
      body: JSON.stringify({
        from: from.toLocaleISODate(),
        to: to?.toLocaleISODate() ?? ''
      })
    });
  }

  async cancelRequest(from: Date): Promise<void> {
    cache.clear(`bookings-${undefined}`);
    await this.fetch(`/api/holiday/booking/${from.toLocaleISODate()}`, {
      method: 'DELETE'
    });
  }

  async bookingsForUsers(
    year: number,
    month: number,
    users: string[]
  ): Promise<IUserBookings[]> {
    if (!users.length) {
      return [];
    }

    const iso_month = new Date(year, month - 1).toLocaleISOMonth();
    return cache.get<IUserBookings[]>(
      `bookings-${iso_month}-${users.join('-')}`,
      async () => {
        const response = await this.fetch(
          `/api/holiday/for?&month=${iso_month}${users.map((user) => `&id=${user}`).join('')}`
        );
        return await response.json();
      }
    );
  }
}
