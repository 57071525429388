import { useState } from 'react';
import { MonthPickerView } from './months-picker-view';

export interface MonthPickerProps {
  year: number;
  month: number;
  onChange?: (year: number, month: number) => void;
}

export const MonthPicker = (props: MonthPickerProps) => {
  const [year, setYear] = useState(props.year);
  const [month, setMonth] = useState(props.month);

  const handleNudgeMonth = (adjusment: number) => {
    const d = new Date(year, month + adjusment - 1);
    setYear(d.getFullYear());
    setMonth(d.getMonth() + 1);
    props.onChange?.(d.getFullYear(), d.getMonth() + 1);
  };

  const onMonthChange = (year: number, month: number) => {
    const d = new Date(year, month - 1);
    setYear(d.getFullYear());
    setMonth(d.getMonth() + 1);
    props.onChange?.(d.getFullYear(), d.getMonth() + 1);
  };

  const current = new Date(year, month - 1);

  return (
    <div className="flex items-center gap-1">
      <span
        className="material-icons outline-focus rotate-180 cursor-pointer rounded-full p-1 text-black/50 hover:bg-neutral-200"
        tabIndex={0}
        style={{ userSelect: 'none' }}
        onClick={handleNudgeMonth.bind(this, -1)}
      >
        arrow_forward_ios
      </span>
      <div
        tabIndex={0}
        className="outline-focus group relative flex-1 cursor-pointer rounded-md border py-1 pr-3 text-center text-sm md:text-base"
      >
        {current.toLocaleDateString('de-DE', {
          month: 'long',
          year: 'numeric'
        })}
        <span className="material-icons absolute right-0 top-1/2 -translate-y-1/2">
          arrow_drop_down
        </span>
        <div className="shadow-hdr absolute top-10 z-50 hidden bg-white group-focus-within:block">
          <MonthPickerView
            year={props.year}
            month={props.month}
            onChange={onMonthChange.bind(this)}
          />
        </div>
      </div>
      <span
        className="material-icons outline-focus cursor-pointer rounded-full p-1 text-black/50 hover:bg-neutral-200"
        tabIndex={0}
        style={{ userSelect: 'none' }}
        onClick={handleNudgeMonth.bind(this, 1)}
      >
        arrow_forward_ios
      </span>
    </div>
  );
};
