import { HeaderMenuContainer, HeaderMenuItem } from './header-menu';
import { IconGroup } from './icons';

export interface HeaderGroupProps {
  groups: { title: string; href: string; selected?: boolean }[];
}

export const HeaderGroupSelector = (props: HeaderGroupProps) => {
  return (
    <div className="border-r-silver-400 text-hdr2 group relative inline-flex h-7 cursor-pointer items-center border-r pl-1 pr-[5px]">
      <span className="outline-focus outline-2 outline-offset-2" tabIndex={0}>
        <IconGroup />
      </span>
      <HeaderMenuContainer position="left" top={25}>
        {props.groups.map((group) => (
          <HeaderMenuItem
            key={group.title}
            title={group.title}
            href={group.href}
            selected={group.selected}
          />
        ))}
      </HeaderMenuContainer>
    </div>
  );
};
