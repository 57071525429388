import { IMemberStats } from '@kidsmanager/util-models';
import { ExtendedUserStats } from '../../roster-editor/roster-editor';

export const compileStats = (
  stats: IMemberStats[],
  holidays: { id: string; days: number }[]
): ExtendedUserStats[] => {
  return stats.map((stat) => {
    const holiday = holidays.find((h) => h.id === stat.id);
    return {
      ...stat,
      holidays: holiday?.days || 0
    };
  });
};
