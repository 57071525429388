import '@kidsmanager/util-extensions';
import {
  IMemberAssigments,
  IReleasedPlan,
  IShiftSpecUpdate,
  IUserBookings
} from '@kidsmanager/util-models';
import { KeyHoliday } from './compiler-shift';
import { VersionState } from '../../roster-versioner/roster-versioner';

const extractMonthDay = (date: string) => {
  const [year, month, day] = date.split('-').map((n) => parseInt(n));
  return { year, month, day };
};

export const compilePlan = (
  year: number,
  month: number,
  plan: IMemberAssigments[],
  bookings: IUserBookings[],
  publicHols: Date[],
  releases: IReleasedPlan[]
): {
  plan: IMemberAssigments[];
  hols: { id: string; days: number }[];
  status: VersionState;
} => {
  plan = structuredClone(plan);

  const hols: { id: string; days: number }[] = [];
  const daysInMonth = new Date(year, month, 0).getDate();
  for (const member of plan) {
    hols.push({ id: member.id, days: 0 });
    const match = bookings.find((b) => b.id === member.id);
    if (!match || match.bookings.length === 0) continue;
    for (const booking of match.bookings) {
      const from = extractMonthDay(booking.from);
      const to = extractMonthDay(booking.to);
      const start = from.month === month ? from.day - 1 : 0;
      const end = to.month === month ? to.day - 1 : daysInMonth - 1;
      for (let i = start; i <= end; i++) {
        const day = new Date(year, month - 1, i + 1);
        const isWeekend = day.isWeekend();
        const isPublicHoliday = !!publicHols.find((h) => h.dateMatches(day));
        if (!isWeekend && !isPublicHoliday) {
          hols[hols.length - 1].days += 1;
        }
        member.assigned[i].shifts.push(KeyHoliday);
      }
    }
  }

  const lastModification = plan.reduce(
    (max, member) => {
      if (!member.modified) return max;
      const timestamp = new Date(member.modified);
      return !max || timestamp > max ? timestamp : max;
    },
    undefined as Date | undefined
  );
  const lastRelease = new Date(
    releases.length > 0 ? releases[releases.length - 1].releasedAt : 0
  );

  const status: VersionState = !lastModification
    ? 'empty'
    : lastModification > lastRelease
      ? 'saved'
      : 'released';

  return { plan, hols, status };
};

export const updatePlanWithSpecChanges = (
  plan: IMemberAssigments[],
  value: IShiftSpecUpdate
): { plan: IMemberAssigments[]; isDirty: boolean } => {
  let isDirty = false;
  for (const member of plan) {
    for (const assignment of member.assigned) {
      for (const shift of assignment.shifts) {
        if (shift.length === 0) {
          continue;
        }

        for (const change of value.changes) {
          switch (change.action) {
            case 'deleted':
              isDirty = true;
              assignment.shifts = assignment.shifts.filter((s) => s !== shift);
              break;
            case 'updated':
              isDirty = true;
              assignment.shifts = assignment.shifts.map((s) =>
                s === change.prior ? change.id : s
              );
              break;
          }
        }
      }
    }
  }
  return { plan, isDirty };
};
