export interface RosterRowLabelProps {
  date: Date;
}

export const RosterRowLabel = (props: RosterRowLabelProps) => {
  const dayOfWeek = props.date.getDay();
  const dayOfMonth = props.date.getDate().toString().padStart(2, '0');

  if (dayOfWeek === 0) {
    return (
      <div className="text-nowrap pl-4 pr-2 text-right text-xs">
        So {dayOfMonth}
      </div>
    );
  }
  if (dayOfWeek === 6) {
    return (
      <div className="text-nowrap pl-4 pr-2 text-right text-xs">
        Sa {dayOfMonth}
      </div>
    );
  }
  return (
    <div className="text-nowrap pl-4 pr-2 text-right text-xs">{dayOfMonth}</div>
  );
};
