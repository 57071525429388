import { useState, useId } from 'react';

export interface CheckboxProps {
  checked?: boolean;
  label?: string;
  onChange?: (checked: boolean) => void;
}

export const Checkbox = (props: CheckboxProps) => {
  const [checked, setChecked] = useState(props.checked || false);

  const id = useId();
  const onChange = () => {
    setChecked((prev) => {
      props.onChange?.(!prev);
      return !prev;
    });
  };

  return (
    <label
      className="inline-flex cursor-pointer items-center gap-2 leading-8"
      htmlFor={id}
    >
      <input
        id={id}
        type="checkbox"
        className="border-silver-400 checked:bg-checkmark checked:animate-checkmark outline-focus h-5 w-5 cursor-pointer appearance-none rounded-md border bg-no-repeat outline-offset-4"
        checked={checked}
        onChange={onChange.bind(this)}
      />
      <span>{props.label}</span>
    </label>
  );
};
