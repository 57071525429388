export const applyMask = (value: string, mask: 'time' | '48h' | undefined) => {
  if (mask === 'time') {
    return applyTimeMask(value);
  }
  return value;
};

const applyTimeMask = (value: string) => {
  const time = value.replace(/[^0-9:]/g, '');
  return time;
};
