import { IAccessMap, IProfile } from '@kidsmanager/util-models';
import { IAuth, fetch } from './auth';
import { BackendCache } from './backend-cache';

const cache = new BackendCache();

export class ApiSettings {
  constructor(
    private auth: IAuth,
    private fetch: fetch
  ) {}

  async accessMap(): Promise<IAccessMap> {
    return cache.get<IAccessMap>('access-map', async () => {
      return (await this.fetch('api/settings/access-map')).json();
    });
  }

  async profile(): Promise<IProfile> {
    return cache.get('profile', async () => {
      return (await this.fetch('api/settings/profile')).json();
    });
  }
}
