import { Checkbox, IconButton } from '@kidsmanager/ui-core';
import { IRosterTemplateSaveAs } from '@kidsmanager/util-models';

export interface SaveAsProps {
  options: IRosterTemplateSaveAs[];
}

export const SaveAs = (props: SaveAsProps) => {
  return (
    <label className="form-control w-full">
      <div className="label">
        <span className="text-sm font-medium">Speichern als</span>
      </div>
      <div className="border-silver-200 relative min-h-44 rounded border bg-white p-4">
        <table className="mb-8 w-full border-separate border-spacing-y-1">
          <thead className="text-left text-xs">
            <tr>
              <th>Name</th>
              <th>Erkl&auml;rung</th>
              <th>Abk&uuml;rzung</th>
              <th>Nur Info</th>
              <th className="w-10"></th>
            </tr>
          </thead>
          <tbody>
            {props.options.map((option) => (
              <tr key={option.id} className="my-1 odd:bg-neutral-100">
                <td className="pl-2">{option.name}</td>
                <td>{option.description}</td>
                <td>{option.abbreviation}</td>
                <td className="text-center align-bottom">
                  <Checkbox checked={option.infoOnly} />
                </td>
                <td className="text-center">
                  <IconButton>more_vert</IconButton>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="absolute bottom-0 right-1">
          <IconButton color="ghost">add</IconButton>
        </div>
      </div>
    </label>
  );
};
