export interface IconButtonProps {
  children: string;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  left?: boolean;
  right?: boolean;
  color?: 'ghost';
}

export const IconButton = (props: IconButtonProps) => {
  return props.color === 'ghost' ? (
    <button
      onClick={props.onClick}
      className={`material-icons outline-focus outline-offset-2 ${(props.left || props.right) && 'absolute'} z-0 h-8 w-8 cursor-pointer rounded-full hover:bg-neutral-200 active:bg-neutral-50 ${props.left && 'left-0'} ${props.right && 'right-0'}`}
      style={{
        userSelect: 'none',
        opacity: props.disabled ? 0.5 : 1
      }}
      disabled={props.disabled}
    >
      {props.children}
    </button>
  ) : (
    <button
      onClick={props.onClick}
      className={`material-icons border-silver-400 outline-focus border ${(props.left || props.right) && 'absolute'} hover:bg-silver-200 z-0 h-8 w-8 cursor-pointer rounded bg-neutral-200 active:bg-neutral-50 ${props.left && 'left-0'} ${props.right && 'right-0'}`}
      style={{ userSelect: 'none', opacity: props.disabled ? 0.5 : 1 }}
      disabled={props.disabled}
    >
      {props.children}
    </button>
  );
};
