import { useContext, useEffect, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { ClientBackend, ClientBackendContext } from '@kidsmanager/ui-api';
import { Header } from '@kidsmanager/ui-core';
import { Role } from '@kidsmanager/util-storage';
import { emptyAccessMap, IAccessMap } from '@kidsmanager/util-models';

export interface AppGuardProps {
  requires?: Role;
}

export const AppGuard = ({ requires }: AppGuardProps) => {
  const client = useContext(ClientBackendContext);
  const params = useParams();
  const navigate = useNavigate();

  const [accessMap, setAccessMap] = useState<IAccessMap>(emptyAccessMap);
  const [groupId, setGroupId] = useState<string | undefined>(params.groupId);

  useEffect(() => {
    setGroupId(params.groupId);
  }, [params]);

  useEffect(() => {
    const handleGoogleElevationMessage = async (event: {
      data: {
        success: boolean;
        tenant: string;
        token: string;
        refreshToken: string;
      };
    }) => {
      window.removeEventListener('message', handleGoogleElevationMessage);
      if (event?.data?.success) {
        client.auth.updateTokens(event.data.token, event.data.refreshToken);
      } else {
        navigate('/');
      }
    };

    const executeSecureRoute = (client: ClientBackend, groupId?: string) => {
      client.settings.accessMap().then((map) => {
        if (requires && !client.auth.inRole(requires)) {
          console.warn(`'${requires}' permission missing for route`);
          navigate('/login');
        }

        if (requires === 'admin' && client.auth.requiresSsoElevation()) {
          //TODO: could we do this with a navigate(`/login?type=elevate&returnUrl=${window.location.pathname}`);
          const tenant = localStorage.getItem('sso-tenant');
          const origin = window.location.origin;
          client.apiOauth2
            .googleWorkspaceUrl('ADMIN', tenant, origin, client.auth.email())
            .then((url: { url: string }) => {
              const width = 600;
              const height = 700;
              const left = window.screen.width / 2 - width / 2;
              const top = window.screen.height / 2 - height / 2;
              const windowProps = `toolbar=no, menubar=no, width=${width}, height=${height}, top=${top}, left=${left}`;
              window.addEventListener('message', handleGoogleElevationMessage);
              window.open(url.url, '', windowProps);
            });
        }
        if (groupId && !map.groups.find((g) => g.id === groupId)) {
          console.warn(`User has no access to group '${groupId}'`);
          navigate('/');
        }
        setAccessMap(map);
      });
    };

    if (client.auth.isAuthenticated()) {
      executeSecureRoute(client, groupId);
    } else {
      console.log('Refreshing token');
      client.auth.refresh().then((state) => {
        if (state === 'Authenticated') {
          executeSecureRoute(client, groupId);
        } else if (window.location.pathname.length > 1) {
          navigate(`/login?returnUrl=${window.location.pathname}`);
        } else {
          navigate(`/login`);
        }
      });
    }
  }, [client, navigate, requires, groupId]);

  return client.auth.isAuthenticated() ? (
    <>
      <Header {...accessMap} />
      <Outlet />
    </>
  ) : undefined;
};
