import { IUserGroup } from '@kidsmanager/util-models';
import { IAuth, fetch } from './auth';
import { BackendCache } from './backend-cache';

const cache = new BackendCache();

export class ApiUsers {
  constructor(
    private auth: IAuth,
    private fetch: fetch
  ) {}

  async active(): Promise<IUserGroup[]> {
    return cache.get('active-users', async () => {
      return (await this.fetch('api/work/users')).json();
    });
  }
}
