import { HeaderMenuContainer } from './header-menu';
import { IconDropdown } from './icons';

export interface HeaderMenuProps {
  title: string;
  children?: React.ReactNode;
}

export const HeaderUserMenu = (props: HeaderMenuProps) => {
  return (
    <div className="group relative">
      <div
        tabIndex={0}
        className="outline-focus peer inline-flex cursor-pointer items-center gap-1 px-1"
      >
        {props.title} <IconDropdown />
      </div>
      <HeaderMenuContainer>{props.children}</HeaderMenuContainer>
    </div>
  );
};
