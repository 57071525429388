import { IGroup } from '@kidsmanager/util-models';
import { IAuth, fetch } from '../auth';
import { BackendCache } from '../backend-cache';

const cache = new BackendCache();

export class ApiAdminGroups {
  constructor(
    private auth: IAuth,
    private fetch: fetch
  ) {}

  async all(): Promise<IGroup[]> {
    // can be cached
    return [];
  }
}
