import { useParams } from 'react-router-dom';

export const AdminUserDetailShifts = () => {
  const params = useParams<{ id: string }>();

  return (
    <>
      {' '}
      <div className="">Shifts for {params.id}.</div>
    </>
  );
};
