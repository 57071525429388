import { IMembership } from '@kidsmanager/util-models';
import { emptyUserData, UserData } from '../../roster-models';
import { ExtendedUserStats } from '../roster-editor';

const MaxAdjustment = 15;

export const buildUsers = (
  users: IMembership[],
  stats: ExtendedUserStats[],
  publicHols: Date[],
  year: number,
  month: number
): UserData[] => {
  if (publicHols.length === 0 || users.length === 0 || stats.length === 0) {
    return Array.from({ length: 8 }).map((_, i) => ({
      ...emptyUserData,
      id: `fake-${i}`
    }));
  }
  const start = new Date(year, month - 1, 1);
  const end = new Date(year, month, 0);
  const workingDays = start.workingDaysUntil(end, publicHols);
  return users.map((u) =>
    buildUser(
      u,
      workingDays,
      stats.find((x) => x.id === u.id)
    )
  );
};

export const buildUser = (
  user: IMembership,
  workingDays: number,
  stats?: ExtendedUserStats
): UserData => {
  const hoursPerDay = user.contractedHrs / 5;
  const holidays = (stats?.holidays || 0) * hoursPerDay;
  const carryOver = stats?.carryOver || 0;
  const hoursInMonth = hoursPerDay * workingDays;
  const maxCarryOver =
    carryOver > 0
      ? Math.min(MaxAdjustment, carryOver)
      : Math.max(-MaxAdjustment, carryOver);
  const goal = hoursInMonth - maxCarryOver - holidays;

  return {
    id: user.id,
    name: user.firstname,
    contractedHrs: user.contractedHrs,
    holidays,
    carryOver,
    hoursInMonth,
    workingDays,
    adjustment: -maxCarryOver,
    goal,
    available: goal
  };
};
